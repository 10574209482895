export const SubmissionStates = {
  EDITING: 'editing',
  SUBMITTING: 'submitting',
  SUBMITTED: 'submitted',
};

export const DisplayTypes = {
  INLINE: 'inline',
  INVOICE_MOBILE_ANDROID: 'invoice_mobile_android', // native Android UI styling
  INVOICE_MOBILE_IOS: 'invoice_mobile_ios', // native iOS UI styling
  INVOICE_MOBILE_V1_0_0: 'invoice_mobile_v1_0_0', // used on old iOS app
  NEXT_WAVE_BUSINESS_OWNER_V1_0_0: 'next_wave_business_owner_v1_0_0', // desktop app, Next Wave payment form
  NEXT_WAVE_BUSINESS_OWNER_AUTO_PAY_V1_0_0: 'next_wave_business_owner_auto_pay_v1_0_0', // for recurring auto payments
  MODAL: 'modal',
  INSTANT_PAYOUT_TRANSACTION_V1_0_0: 'instant_payout_transaction_v1_0_0', // desktop app, similar to NEXT_WAVE_BUSINESS_OWNER_V1_0_0 but for only instant payout
};

export const FormModes = {
  SALE: 'sale',
  SAVE: 'save',
  SALE_AND_SAVE: 'saleAndSave',
};

export const Orientations = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  RESPONSIVE: 'responsive',
};

export const ErrorTypes = {
  EXTERNAL: 'external',
  TOKENIZATION: 'tokenization',
  VALIDATION: 'validation',
};

export const Providers = {
  PAY_PAGE: 'pay_page',
  STRIPE: 'stripe',
  FINIX: 'finix',
};

// From Stripe's API Docs https://stripe.com/docs/api#errors-type
export const StripeErrorTypes = {
  API_CONNECTION_ERROR: 'api_connection_error',
  API_ERROR: 'api_error',
  AUTHENTICATION_ERROR: 'authentication_error',
  CARD_ERROR: 'card_error',
  INVALID_REQUEST_ERROR: 'invalid_request_error',
  RATE_LIMIT_ERROR: 'rate_limit_error',
};

export const MastercardBins = [
  51,
  52,
  53,
  54,
  55,
  2221,
  2222,
  2223,
  2224,
  2225,
  2226,
  2227,
  2228,
  2229,
  223,
  224,
  225,
  226,
  227,
  228,
  229,
  23,
  24,
  25,
  26,
  270,
  271,
  2720,
];

export const PlusCardBins = [589297, 451901, 451902, 451903];

export const Country = {
  CANADA: 'ca',
  US: 'us',
};

export const CreditCardNumberErrorTypes = {
  NO_NUMBER_ERROR: 'Card number is required',
  CREDIT_CARD_INCOMPLETE_NUMBER_ERROR: 'Enter card number with more than 13 digits',
  DEBIT_CARD_US_INCOMPLETE_NUMBER_ERROR: 'Enter 16-digit card number',
  DEBIT_CARD_CA_INCOMPLETE_NUMBER_ERROR: 'Enter 16 or 19-digit card number',
  NO_AMEX_ALLOWED_ERROR: "This business doesn't accept American Express",
  INVALID_CARD_ERROR: 'Enter a valid card number',
  IP_UNSUPPORTED_CARD_ERROR: ' is not supported for Instant Payouts',
};

export const CardTypes = {
  PLUS: 'plus',
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  AMEX: 'amex',
  DINERSCLUB: 'dinersclub',
  DISCOVER: 'discover',
  JCB: 'jcb',
};

export const SupportedCanadianIPCards = [CardTypes.VISA, CardTypes.PLUS];
export const SupportedUSIPCards = [CardTypes.VISA, CardTypes.MASTERCARD];
